import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import HomeView from "../views/HomeView.vue"
import LoginView from "../views/LoginView.vue"
import ShopListView from "../views/shop/ShopList.vue"
import AddShop from "../views/shop/AddShop.vue"
import EmceeList from "../views/emcee/list.vue"
import EditEmcee from "../views/emcee/edit.vue"
import GamepointList from "../views/gamepoint/GamepointList.vue"
import GamepointChange from "../views/gamepoint/GamepointChange.vue"
import AccountView from "../views/system/AccountView.vue"
import OperatorView from "../views/system/OperatorView.vue"
import HomePage from "../views/homepage/HomePage.vue"
import PlayerList from "../views/player/PlayerList.vue"
import PlayerFeedback from "../views/player/PlayerFeedback.vue"
import PlayerStatus from "../views/player/PlayerStatus.vue"
import PlayerCount from "../views/player/PlayerCount.vue"
import PlayerDetail from "../views/player/PlayerDetail.vue"
import GameRecord from "../views/game/GameRecord.vue"
import PersonStatistics from "../views/game/personStatistics.vue"
import GameLog from "../views/game/GameLog.vue"
import GamePlayer from "../views/game/GamePlayer.vue"
import Point from "../views/point/Point.vue"
import Demo from "../views/demo/demo.vue"
import SeasonView from "../views/season/SeasonView.vue"
import AddSeason from "@/views/season/AddSeason.vue"
import integralRecord from "../views/integral/integralRecord.vue"
import GameManage from "../views/game/GameManage.vue"
import letterOfInvitation from "../views/specialItems/letterOfInvitation.vue"
import JDice from "../views/specialItems/JDice.vue"
import record from "../views/specialItems/record.vue"
import distribution from "../views/specialItems/distribution.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login", // 设置根路径重定向到 /home
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/homepage",
        name: "homepage",
        component: HomePage,
      },
      {
        path: "/shoplist",
        name: "shoplist",
        component: ShopListView,
      },
      {
        path: "/addshop",
        name: "addShop",
        component: AddShop,
      },
      {
        path: "/emceeList",
        name: "emceeList",
        component: EmceeList,
      },
      {
        path: "/editEmcee",
        name: "editEmcee",
        component: EditEmcee,
      },
      {
        path: "/gamepointList",
        name: "gamepointList",
        component: GamepointList,
      },
      {
        path: "/gamepointChange",
        name: "gamepointChange",
        component: GamepointChange,
      },
      {
        path: "/accountView",
        name: "accountView",
        component: AccountView,
      },
      {
        path: "/operatorView",
        name: "OperatorView",
        component: OperatorView,
      },
      {
        path: "/playerlist",
        name: "PlayerList",
        component: PlayerList,
      },
      {
        path: "/playerfeedback",
        name: "PlayerFeedback",
        component: PlayerFeedback,
      },
      {
        path: "/playerdetail",
        name: "PlayerDetail",
        component: PlayerDetail,
      },
      {
        path: "/playercount",
        name: "PlayerCount",
        component: PlayerCount,
      },
      {
        path: "/playerstatus",
        name: "PlayerStatus",
        component: PlayerStatus,
      },
      {
        path: "/gamerecord",
        name: "GameRecord",
        component: GameRecord,
      },
      {
        path: "/personStatistics",
        name: "PersonStatistics",
        component: PersonStatistics,
      },
      {
        path: "/gamelog",
        name: "GameLog",
        component: GameLog,
      },
      {
        path: "/gameplayer",
        name: "GamePlayer",
        component: GamePlayer,
      },
      {
        path: "/pointset",
        name: "Point",
        component: Point,
      },
      {
        path: "/gamemanage",
        name: "GameManage",
        component: GameManage,
      },
      // {
      //   path: '/demo',
      //   name: 'Demo',
      //   component: Demo
      // },
      {
        path: "/seasonView",
        name: "SeasonView",
        component: SeasonView,
      },
      {
        path: "/addSeason",
        name: "AddSeason",
        component: AddSeason,
      },
      {
        path: "/integralRecord",
        name: "IntegralRecord",
        component: integralRecord,
      },
      {
        path: "/letterOfInvitation",
        name: "letterOfInvitation",
        component: letterOfInvitation,
      },
      {
        path: "/JDice",
        name: "JDice",
        component: JDice,
      },
      {
        path: "/record",
        name: "record",
        component: record,
      },
      {
        path: "/distribution",
        name: "distribution",
        component: distribution,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
