<template>
	<div class="GameLogList">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div style="margin-top: 20px" class="formhead">
			<div class="row">
				<el-form-item label="玩家编号">
					<el-input v-model="playerNum" placeholder="请输入玩家编号" />
				</el-form-item>
				<el-form-item label="获胜情况">
					<el-select v-model="victoryState" placeholder="全部" style="marginleft: 20px">
						<el-option label="全部" value="" />
						<el-option label="获胜" :value="1" />
					</el-select>
				</el-form-item>
			</div>
		</div>
		<div class="btngroup">
			<el-button @click="editPoints(1)">清除积分</el-button>
			<el-button type="primary" @click="editPoints(0)">恢复积分</el-button>
			<el-button @click="handleReset">重置</el-button>
			<el-button type="primary" @click="handleSearch">搜索</el-button>
		</div>
		<div class="gameNumber">
			{{ content }}
		</div>
		<div class="tablewrap">
			<el-table
				:data="tableData"
				@sort-change="handleSortChange"
				style="width: 100%"
				empty-text="还没有数据哦~"
				stripe
				@selection-change="handleSelectionChange"
			>
			    <el-table-column
					type="selection"
				>
				</el-table-column>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'status'">
							{{
								scope.row[item.prop] == 1
									? "获胜"
									: scope.row[item.prop] == 2
									? "存活"
									: scope.row[item.prop] == 3
									? "失败"
									: "/"
							}}
						</div>
						<div v-else-if="item.prop == 'unlockStatus'">
							{{
								scope.row[item.prop] == 0 ? "新解锁" : scope.row[item.prop] == 1 ? "已解锁" : "/"
							}}
						</div>
            <div v-else-if="item.prop == 'obtainIntegral' && scope.row.disable == 1" class="obtainIntegral">
              {{ scope.row[item.prop] }}
            </div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="详情" width="185">
					<template #default="scope">
						<el-button link type="primary" @click="handleupdate(scope.row, 'detail')"
							>查看>></el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get, post } from "../../utils/request";
import dayjs from "dayjs";
import { Decrypt, Encrypt } from "../../utils/secret";
import { ElMessage } from "element-plus";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "游戏" },
	{ key: 2, value: "场次信息" },
	{ key: 3, value: "人数统计" },
];
const gameObject = reactive({ data: "" });
const playerNum = ref(undefined);
const victoryState = ref("");
let tableData = ref([]);
const content = ref("");
const gameId = ref("");
const tablecolumn = reactive([
	{
		prop: "id",
		label: "玩家编号",
		width: "",
	},
	{
		prop: "nickname",
		label: "昵称",
		width: "",
	},
	{
		prop: "seatNumber",
		label: "座位号",
		width: "",
	},
	{
		prop: "career",
		label: "职业",
		width: "",
	},
	{
		prop: "status",
		label: "获胜情况",
		width: "",
	},
	{
		prop: "unlockStatus",
		label: "解锁状态",
		width: "",
	},
	{
		prop: "consumePoint",
		label: "消耗点数",
		width: "",
	},
	{
		prop: "obtainIntegral",
		label: "获得积分",
		width: "",
	},
]);
onMounted(() => {
	// 先解码再解密
	if (router.currentRoute.value.query.gameData) {
		const encodedData = JSON.parse(
			Decrypt(decodeURIComponent(router.currentRoute.value.query.gameData))
		);
		gameObject.data = encodedData;
	}
	getlist();
});
const handleReset = () => {
	playerNum.value = undefined;
	victoryState.value = "";
	getlist();
};
const handleSearch = () => {
	getlist();
}
const getlist = () => {
	let url = `/manager/game/session/detail/v2?sessionId=${gameObject.data.id}`;
	if (playerNum.value !== undefined) {
		url = url + `&userId=${playerNum.value}`;
	}
	if (victoryState.value !== "") {
		url = url + `&status=${Number(victoryState.value)}`;
	}
	get(url).then((res) => {
		// console.log(JSON.stringify(res));
		if (res.code == 1) {
			tableData.value = res.result.list;
			content.value = res.result.content;
			gameId.value = res.result.gameId;
			tablecolumn.map((item) => {
				if (item.prop == "career") {
					if (res.result.gameId == 1) {
						item.label = "职业";
					} else {
						item.label = "玩家角色";
					}
				}
			});
		}
	});
};
const handleupdate = (e, b) => {
	e.gameId = gameId.value;
	let passData = encodeURIComponent(Encrypt(JSON.stringify(e)));
	router.push(`/gameplayer?gameData=${passData}`);
};
// 清除/恢复积分
const integral = ref([])
// 当表格多选框出现变化触发 - 选中的玩家ID
const handleSelectionChange = (val) => {
	integral.value = []
	val.forEach((item) => {
       integral.value.push(item.id)
	})
}
// 编辑积分
const editPoints = (v) =>{
  if (Array.from(integral.value).length === 0) {
    ElMessage({
      message: "未选择玩家！",
      type: "error",
    });
    return
  }
  const action = ref(v)
  let url = `/manager/game/session/detail/point/edit?userIds=${Array.from(integral.value)}&sessionId=${gameObject.data.id}&action=${action.value}`
  get(url).then((res) => {
    if (res.code === 1) {
      ElMessage({
        message: res.message,
        type: "success",
      });
      getlist();
    }
  });
}
</script>

<style lang="less" scoped>
.GameLogList {
	width: 98%;
	padding: 10px 10px;
}
.flexbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.btngroup {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}
.tablewrap {
	margin-top: 10px;
}
.hoverclass {
	cursor: pointer;
}
.paginationwrap {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: flex-end;
}
.hoverclass:hover {
	color: var(--el-color-primary);
}
.el-input {
	width: 200px;
}
.obtainIntegral{
  text-decoration: line-through;
}
.formhead {
	.row {
		display: flex;
		> div {
			margin-left: 20px;
		}
		> div:first-child {
			margin-left: 0px;
		}
	}
	/deep/ .el-range-editor.el-input__wrapper {
		width: 250px;
	}
}
</style>
