<template>
  <div class="boss">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div class="table">
      <el-table
        :data="parentLevelData"
        style="width: 100%"
        empty-text="还没有数据哦~"
        stripe
        :row-key="getRowKey"
      >
        <el-table-column
          v-for="(item, index) in listTitleOne"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="item.prop === 'invitationType'">
              {{ scope.row[item.prop] == 1 ? "赠送" : "自用" }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tips">
      <div class="item">
        已选中<text>{{ isSelectAll ? total : selectQuantity }}</text
        >位玩家
      </div>
      <div class="item">
        发放数量<el-input
          v-model="sum"
          placeholder="请输入发放数量"
          style="width: 200px; margin-left: 10px"
        ></el-input>
      </div>
      <div class="item">
        本次共发放<text>{{
          isSelectAll ? total * sum : selectQuantity * sum
        }}</text
        >个邀请函
      </div>
      <div class="item" @click="showConfirmation">
        <el-button type="primary">确认发放</el-button>
      </div>
    </div>
    <div style="margin-top: 20px" class="formhead">
      <div class="row">
        <el-form-item label="玩家编号">
          <el-input v-model="id" placeholder="请输入玩家编号" />
        </el-form-item>
        <el-form-item label="赛季积分">
          <el-form-item label="最小值" style="margin-right: 10px">
            <el-input v-model="integralMin" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="最大值">
            <el-input v-model="integralMax" placeholder="请输入" />
          </el-form-item>
        </el-form-item>
        <el-form-item label="玩家类型">
          <el-select
            multiple
            v-model="role"
            placeholder="请选择玩家类型"
            style="width: 250px"
          >
            <el-option label="普通玩家" :value="0" />
            <el-option label="J-Gamer" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="玩家等级">
          <el-select
              multiple
              v-model="grades"
              placeholder="请选择玩家类型"
              style="width: 250px"
          >
            <el-option v-for="item in playList" :key="item.id" :label="item.grade" :value="item.grade" />
          </el-select>
        </el-form-item>
        <el-form-item label="已解锁游戏">
          <el-select multiple v-model="gameIds" placeholder="请选择已解锁游戏" style="marginleft: 20px">
            <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in gamelist"
                :key="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="玩家创建时间">
          <el-date-picker
              v-model="timeArray"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="玩家最近游戏日期">
          <el-date-picker
              v-model="timeArrayTwo"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>
      </div>
    </div>
    <div class="btngroup">
      <el-button @click="handleReset">重置</el-button>
      <el-button type="primary" @click="getList">确认搜索</el-button>
    </div>
    <div class="table">
      <el-table
        :data="list"
        style="width: 100%"
        empty-text="还没有数据哦~"
        stripe
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :row-key="getRowKey"
        @select="selectOne"
      >
        <el-table-column :reserve-selection="true" type="selection" width="55">
        </el-table-column>
        <el-table-column
          v-for="(item, index) in listTitleTwo"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">
              -
            </div>
            <div v-else-if="item.prop == 'createTime'">
              {{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
            <div class="hoverclass" v-else-if="item.prop == 'integral'">
              {{ scope.row[item.prop] }}
            </div>
            <div class="hoverclass" v-else-if="item.prop == 'status'">
              {{ scope.row[item.prop] == 0 ? "普通" : "黑名单" }}
            </div>
            <div class="hoverclass" v-else-if="item.prop == 'role'">
              {{ scope.row[item.prop] == 0 ? "普通" : "J-Gamer" }}
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-checkbox v-model="isSelectAll" @change="selectSllChange"
        >全选所有</el-checkbox
      >
      <el-pagination
        class="paging fr"
        @current-change="handleCurrentChange"
        :current-page="page"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="size"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      v-model="isConfirmation"
      title="确认发放"
      width="500"
      :before-close="
        () => {
          isConfirmation = false;
        }
      "
    >
      您是否确认发放{{
        isSelectAll ? total * sum : selectQuantity * sum
      }}个邀请函
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="isConfirmation = false">取消</el-button>
          <el-button type="primary" @click="distributionLetter">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref, watch, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { Decrypt, Encrypt } from "@/utils/secret";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import dayjs from "dayjs";
import { get, post } from "@/utils/request";
let parentLevelData = ref([]);
onMounted(() => {
  if (router.currentRoute.value.query.data) {
    const encodedData = JSON.parse(
      Decrypt(decodeURIComponent(router.currentRoute.value.query.data))
    );
    parentLevelData.value = [encodedData];
  }
  getList();
  getGameList()
  getPlayList()
});
const router = useRouter();
const multipleTable = ref(null);
const getRowKey = (row) => {
  return row.id;
};
const navigatelist = ref([
  { key: 1, value: "特殊道具" },
  { key: 2, value: "邀请函管理" },
  { key: 3, value: "发放邀请函" },
]);
// 表格数据
const listTitleOne = reactive([
  { label: "批次号", prop: "batchNumber", width: "100px" },
  { label: "邀请函名称", prop: "invitationName", width: "" },
  { label: "类型", prop: "invitationType", width: "100px" },
  { label: "使用次数", prop: "invitationType", width: "100px" },
  { label: "邀请函内容", prop: "invitationContent", width: "" },
  { label: "起始日", prop: "expirationDate", width: "" },
  { label: "到期日", prop: "expirationDate", width: "" },
  { label: "发放数量", prop: "totalQuantity", width: "100px" },
  { label: "赠送数量", prop: "giftedQuantity", width: "100px" },
  { label: "使用数量", prop: "usedQuantity", width: "100px" },
]);
const listTitleTwo = reactive([
  { label: "玩家编号", prop: "id", width: "" },
  { label: "昵称", prop: "nickname", width: "" },
  { label: "总积分", prop: "integral", width: "" },
  { label: "玩家等级", prop: "grade", width: "" },
  { label: "赛季积分", prop: "seasonIntegral", width: "" },
  { label: "已解锁游戏", prop: "pic", width: "" },
  { label: "注册日期", prop: "createTime", width: "" },
  { label: "最近游戏日期", prop: "lastGameTime", width: "" },
  { label: "玩家类型", prop: "role", width: "" },
]);
let list = ref([]);
const getList = async () => {
  let url = `/manager/player/listGrant?page=${page.value}&size=${size.value}`;
  if (timeArray.value && timeArray.value.length > 0) {
    url =
      url +
      `&start=${dayjs(timeArray.value[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      )}&end=${dayjs(timeArray.value[1]).format("YYYY-MM-DD HH:mm:ss")}`;
  }
  if (timeArrayTwo.value && timeArrayTwo.value.length > 0) {
    url =
        url +
        `&laststart=${dayjs(timeArrayTwo.value[0]).format(
            "YYYY-MM-DD HH:mm:ss"
        )}&lastend=${dayjs(timeArrayTwo.value[1]).format("YYYY-MM-DD HH:mm:ss")}`;
  }
  if (integralMax.value !== undefined) {
    url = url + `&integralMax=${integralMax.value + ""}`;
  }
  if (integralMin.value !== undefined) {
    url = url + `&integralMin=${integralMin.value + ""}`;
  }
  if (id.value) {
    url = url + `&id=${id.value}`;
  }
  if (status.value !== undefined) {
    url = url + `&status=${status.value}`;
  }
  if (role.value.length > 0) {
    url = url + `&role=${role.value.join(',')}`;
  }
  if (seasonId.value !== undefined) {
    url = url + `&seasonId=${seasonId.value + ""}`;
  }
  if (grades.value.length > 0) {
    url = url + `&grades=${grades.value.join(',')}`;
  }
  if (gameIds.value.length > 0) {
    url = url + `&gameIds=${gameIds.value.join(',')}`;
  }
  const { result } = await get(url);
  list.value = result.list;
  total.value = result.total;
};
// 翻页数据
const page = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (val) => {
  page.value = val;
  // getList()
};
const handleSizeChange = (val) => {
  size.value = val;
  // getList();
};
// 发放数据
const sum = ref("");
const selectQuantity = ref(0);
const isSelectAll = ref(false);
const multipleSelection = ref([]);
const isConfirmation = ref(false);
// 表格单页选中状态发生改变
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  selectQuantity.value = val.length;
};
// 全选所有页
const selectSllChange = (val) => {
  if (val) {
    list.value.forEach((res) => {
      multipleTable.value.toggleRowSelection(res, true);
    });
  } else {
    multipleTable.value.clearSelection();
  }
};
const selectOne = () => {
  if (isSelectAll.value) isSelectAll.value = false;
};
const showConfirmation = () => {
  if (multipleSelection.value.length === 0)
    return ElMessage.error("请选择发放玩家");
  if (!sum.value) return ElMessage.error("请输入发放数量");
  isConfirmation.value = true;
};
// 发放邀请函
const distributionLetter = async () => {
  let arr = [];
  if (!isSelectAll.value) {
    multipleSelection.value.forEach((res) => {
      arr.push(res.id);
    });
  }
  let url = `/admin/Tinvitation/send?invitationId=${parentLevelData.value[0].id}&sum=${sum.value}`;
  if (timeArray.value.length > 0) {
    url =
        url +
        `&start=${dayjs(timeArray.value[0]).format(
            "YYYY-MM-DD HH:mm:ss"
        )}&end=${dayjs(timeArray.value[1]).format("YYYY-MM-DD HH:mm:ss")}`;
  }
  if (timeArrayTwo.value.length > 0) {
    url =
        url +
        `&laststart=${dayjs(timeArrayTwo.value[0]).format(
            "YYYY-MM-DD HH:mm:ss"
        )}&lastend=${dayjs(timeArrayTwo.value[1]).format("YYYY-MM-DD HH:mm:ss")}`;
  }
  if (integralMax.value !== undefined) {
    url = url + `&integralMax=${integralMax.value + ""}`;
  }
  if (integralMin.value !== undefined) {
    url = url + `&integralMin=${integralMin.value + ""}`;
  }
  if (id.value) {
    url = url + `&id=${id.value}`;
  }
  if (status.value !== undefined) {
    url = url + `&status=${status.value}`;
  }
  if (role.value.length > 0) {
    url = url + `&role=${role.value.join(',')}`;
  }
  if (seasonId.value !== undefined) {
    url = url + `&seasonId=${seasonId.value + ""}`;
  }
  if (grades.value.length > 0) {
    url = url + `&grades=${grades.value.join(',')}`;
  }
  if (gameIds.value.length > 0) {
    url = url + `&gameIds=${gameIds.value.join(',')}`;
  }
  if (isSelectAll.value) {
    url += "&type=1";
  } else {
    url += `&userIds=${arr}`;
  }
  const { message, code } = await post(url);
  if (code == 0) return ElMessage.error(message);
  parentLevelData.value[0].totalQuantity = Number(parentLevelData.value[0].totalQuantity) + Number(sum.value)
  multipleTable.value.clearSelection();
  isConfirmation.value = false;
  ElMessage({
    message: "发放成功",
    type: "success",
  })
};
// 查询数据
const id = ref(undefined);
const timeArray = ref([]);
const timeArrayTwo = ref([]);
const gamelist = ref([])
const gameIds = ref([])
const status = ref(undefined);
const role = ref([]);
const seasonId = ref("");
const integralMax = ref(undefined)
const integralMin = ref(undefined)
const playList = ref([])
const grades = ref([])
const handleReset = () => {
  id.value = undefined;
  timeArray.value = [];
  timeArrayTwo.value = [];
  status.value = undefined;
  role.value = [];
  grades.value = [];
  seasonId.value = undefined;
  integralMax.value = undefined;
  integralMin.value = undefined;
  gameIds.value = []
};
// 获取游戏列表
const getGameList = async () => {
  const { result } = await get("/manager/game/list", {}, false)
  gamelist.value = result;
};
// 获取玩家列表
const getPlayList = async () => {
  const { result } = await get("/admin/Tinvitation/getLevel", {}, false)
  playList.value = result;
};
watch(page, async () => {
  await getList();
  if (isSelectAll.value) {
    nextTick(() => {
      list.value.forEach((res) => {
        multipleTable.value.toggleRowSelection(res, true);
      });
    });
  }
});
</script>
<style lang="less" scoped>
.boss {
  width: 100%;
  padding: 20px;
  .el-form-item{
    align-items: center;
  }
  .table {
    margin-top: 20px;
  }
  .paginationwrap {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .tips {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
    .item {
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
    text {
      color: #409eff;
      margin: 0 6px;
    }
  }
  .formhead {
    .row {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-right: 20px;
      }
      > div:first-child {
        margin-left: 0px;
      }
    }
    /deep/ .el-range-editor.el-input__wrapper {
      width: 350px;
    }
  }
}
</style>
