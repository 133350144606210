<template>
	<div class="shoplist">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="btngroup">
			<el-button type="primary" @click="addshop">新增商户</el-button>
		</div>
		<div class="tablewrap">
			<el-table
				:data="tableData"
				@sort-change="handleSortChange"
				style="width: 100%"
				empty-text="还没有数据哦~"
				stripe
			>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'gameRecentlyStartTime'">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div
							v-else-if="item.prop == 'hostCount'"
							class="hoverclass"
							@click="toEmceeList(scope.row)"
						>
							{{ scope.row[item.prop] }} >>
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="185">
					<template #default="scope">
						<el-button link type="primary" @click="handleupdate(scope.row.id)"
							>修改商家信息</el-button
						>
						<el-button link type="primary" @click="handdel(scope.row.id)" style="color: red"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginationwrap">
			<el-pagination
				class="paging fr"
				@current-change="handleCurrentChange"
				:current-page="page"
				@size-change="handleSizeChange"
				:page-sizes="[10, 20, 50, 100, 200]"
				:page-size="size"
				:pager-count="5"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import dayjs from "dayjs";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { get, post } from "../../utils/request";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "商家" },
	{ key: 2, value: "商家信息" },
];
const page = ref(1);
const size = ref(10);
const total = ref(0);
const timeOrder = ref(0);
let tableData = ref([]);
const tablecolumn = reactive([
	{
		prop: "id",
		label: "商家编号",
		width: 100,
	},
	{
		prop: "name",
		label: "商家名称",
		width: "",
	},
	{
		prop: "region",
		label: "商家城市",
		width: "",
	},
	{
		prop: "phone",
		label: "电话",
		width: "",
	},
	{
		prop: "address",
		label: "商家地址",
		width: "",
	},
	{
		prop: "gameRecentlyStartTime",
		label: "游戏最近开局时间",
		width: "200",
		sortable: "custom",
	},
	{
		prop: "gamePoint",
		label: "游戏点数",
		width: 100,
	},
	{
		prop: "hostCount",
		label: "主持人数",
		width: 100,
	},
]);
onMounted(() => {
	getlist();
});
const addshop = () => {
	router.push("/addshop");
};
const suredel = (id) => {
	let url = `/manager/store/delete?id=${id}`;
	post(url).then((res) => {
		if (res.code === 1) {
			ElMessage({
				message: "删除成功",
				type: "success",
			});
			getlist();
		}
	});
};
const handdel = (id) => {
	ElMessageBox.confirm("是否删除该商家？", "提示", {
		confirmButtonText: "删除",
		cancelButtonText: "取消",
		type: "none",
		center: true,
	})
		.then(() => suredel(id))
		.catch(() => {});
};
const getlist = () => {
	let url = `/manager/store/list?page=${page.value}&size=${size.value}`;
	if (timeOrder.value) {
		url = url + `&timeOrder=${timeOrder.value}`;
	}
	get(url).then((res) => {
		if (res.code === 1) {
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
const handleupdate = (id) => {
	router.push(`/addshop?id=${id}`);
};

const toEmceeList = (data) => {
	router.push("/emceeList?id=" + data.id);
};
const handleSortChange = ({ column, prop, order }) => {
	if (prop == "gameRecentlyStartTime") {
		if (order == "ascending") {
			//升序
			timeOrder.value = 1;
		} else if (order == "descending") {
			//降序
			timeOrder.value = 2;
		} else {
			timeOrder.value = 0;
		}
	}
	getlist();
};
</script>
<style lang="less" scoped>
.dialog-footer button:first-child {
	margin-right: 10px;
}
.shoplist {
	width: 98%;
	padding: 10px;
	.btngroup {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}
	.tablewrap {
		margin-top: 10px;
	}
	.paginationwrap {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: flex-end;
	}
	.hoverclass {
		cursor: pointer;
	}
	.hoverclass:hover {
		color: var(--el-color-primary);
	}
}
</style>
